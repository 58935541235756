import { routesFullPaths } from '@core/routes/app.routes-config';

export type FooterMenuItemData = {
  readonly title: string;
  readonly slug: string[];
};

export type FooterMenuData = {
  readonly title: string;
  readonly items: FooterMenuItemData[];
};

export const FOOTER_MENU_DATA: FooterMenuData[] = [
  {
    title: 'MENU.PALACE_DISTRICT.TITLE',
    items: [
      { title: 'MENU.PALACE_DISTRICT.ABOUT', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.PALACE_DISTRICT.NEWS', slug: routesFullPaths.NEWS },
      { title: 'MENU.PALACE_DISTRICT.SHOPS', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.PALACE_DISTRICT.NEWSLETTER', slug: routesFullPaths.WORK_IN_PROGRESS },
    ],
  },
  {
    title: 'MENU.VISITING.TITLE',
    items: [
      { title: 'MENU.VISITING.THINGS_TO_SEE', slug: routesFullPaths.SIGHTS },
      { title: 'MENU.VISITING.OUR_PROGRAMS', slug: routesFullPaths.PROGRAMS },
    ],
  },
  {
    title: 'MENU.USEFULL_INFORMATIONS.TITLE',
    items: [
      { title: 'MENU.USEFULL_INFORMATIONS.PARKING', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.USEFULL_INFORMATIONS.INFORMATION_POINTS', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.USEFULL_INFORMATIONS.CONTACT_INFORMATION', slug: routesFullPaths.CONTACT_US },
      { title: 'MENU.USEFULL_INFORMATIONS.FAQ', slug: routesFullPaths.WORK_IN_PROGRESS },
    ],
  },
  {
    title: 'MENU.CONTACTS.TITLE',
    items: [
      { title: 'MENU.CONTACTS.ASK_US', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.CONTACTS.FILM_AND_PHOTO_LOCATION', slug: routesFullPaths.WORK_IN_PROGRESS },
      { title: 'MENU.CONTACTS.VENUE_RENTAL', slug: routesFullPaths.AREA_UTILIZATION },
    ],
  },
];
