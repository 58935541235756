import { routesFullPaths } from '@core/routes/app.routes-config';

export type ProfileMenuData = {
  readonly title: string;
  readonly slug: string[];
};

export const PROFILE_MENU_DATA: ProfileMenuData[] = [
  { title: 'PROFILE.TITLE', slug: routesFullPaths.PROFILE },
  { title: 'PROFILE.DATA.TITLE', slug: routesFullPaths.PROFILE_DATA },
  { title: 'PROFILE.BOUGHT-TICKETS.TITLE', slug: routesFullPaths.PROFILE_BOUGHT_TICKETS },
];
