export enum routesEnum {
  HOME = 'HOME',
  NOT_FOUND = 'NOT_FOUND',
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  AUTH = 'AUTH',
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_REGISTRATION = 'AUTH_REGISTRATION',
  AUTH_FORGOTTEN_PASSWORD = 'AUTH_FORGOTTEN_PASSWORD',
  AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
  AUTH_ACTIVATION = 'AUTH_ACTIVATION',
  PROFILE = 'PROFILE',
  PROFILE_DATA = 'PROFILE_DATA',
  PROFILE_PASSWORD_CHANGE = 'PROFILE_PASSWORD_CHANGE',
  PROFILE_DATA_CHANGE = 'PROFILE_DATA_CHANGE',
  PROFILE_BOUGHT_TICKETS = 'PROFILE_BOUGHT_TICKETS',
  NEWS = 'NEWS',
  NEWS_ARTICLE = 'NEWS_ARTICLE',
  NEWS_TAG = 'NEWS_TAG',
  PROGRAMS = 'PROGRAMS',
  SIGHTS = 'SIGHTS',
  CONTACT_US = 'CONTACT_US',
  SEARCH = 'SEARCH',
  LANDING_PAGES = '**',
  LANDING_THE_BUDAVARI_PALACE_DISTRICT = 'LANDING_THE_BUDAVARI_PALACE_DISTRICT',
  AREA_UTILIZATION = 'AREA_UTILIZATION',
  AREA_UTILIZATION_VENUE_RENTAL = 'AREA_UTILIZATION_VENUE_RENTAL',
}

export const routesFullPaths = {
  [routesEnum.HOME]: ['/'],
  [routesEnum.NOT_FOUND]: ['/', routesEnum.NOT_FOUND],
  [routesEnum.WORK_IN_PROGRESS]: ['/', routesEnum.WORK_IN_PROGRESS],
  [routesEnum.AUTH]: ['/', routesEnum.AUTH],
  [routesEnum.AUTH_LOGIN]: ['/', routesEnum.AUTH, routesEnum.AUTH_LOGIN],
  [routesEnum.AUTH_REGISTRATION]: ['/', routesEnum.AUTH, routesEnum.AUTH_REGISTRATION],
  [routesEnum.AUTH_FORGOTTEN_PASSWORD]: ['/', routesEnum.AUTH, routesEnum.AUTH_FORGOTTEN_PASSWORD],
  [routesEnum.AUTH_RESET_PASSWORD]: ['/', routesEnum.AUTH, routesEnum.AUTH_RESET_PASSWORD],
  [routesEnum.AUTH_ACTIVATION]: ['/', routesEnum.AUTH, routesEnum.AUTH_ACTIVATION],
  [routesEnum.PROFILE]: ['/', routesEnum.PROFILE],
  [routesEnum.PROFILE_DATA]: ['/', routesEnum.PROFILE, routesEnum.PROFILE_DATA],
  [routesEnum.PROFILE_PASSWORD_CHANGE]: ['/', routesEnum.PROFILE, routesEnum.PROFILE_PASSWORD_CHANGE],
  [routesEnum.PROFILE_DATA_CHANGE]: ['/', routesEnum.PROFILE, routesEnum.PROFILE_DATA_CHANGE],
  [routesEnum.PROFILE_BOUGHT_TICKETS]: ['/', routesEnum.PROFILE, routesEnum.PROFILE_BOUGHT_TICKETS],
  [routesEnum.NEWS]: ['/', routesEnum.NEWS],
  [routesEnum.NEWS_ARTICLE]: ['/', routesEnum.NEWS, routesEnum.NEWS_ARTICLE],
  [routesEnum.NEWS_TAG]: ['/', routesEnum.NEWS, routesEnum.NEWS_TAG],
  [routesEnum.PROGRAMS]: ['/', routesEnum.PROGRAMS],
  [routesEnum.SIGHTS]: ['/', routesEnum.SIGHTS],
  [routesEnum.CONTACT_US]: ['/', routesEnum.CONTACT_US],
  [routesEnum.SEARCH]: ['/', routesEnum.SEARCH],
  [routesEnum.LANDING_THE_BUDAVARI_PALACE_DISTRICT]: ['/', routesEnum.LANDING_THE_BUDAVARI_PALACE_DISTRICT],
  [routesEnum.AREA_UTILIZATION]: ['/', routesEnum.AREA_UTILIZATION],
  [routesEnum.AREA_UTILIZATION_VENUE_RENTAL]: ['/', routesEnum.AREA_UTILIZATION, routesEnum.AREA_UTILIZATION_VENUE_RENTAL],
};
