import { routesFullPaths } from '@core/routes/app.routes-config';

export type MegamenuItemData = {
  readonly title: string;
  readonly slug: string[];
};

export type MegamenuData = {
  readonly title: string;
  readonly items: MegamenuItemData[];
};

export const MEGAMENU_DATA: MegamenuData[] = [
  {
    title: 'MENU.ABOUT.TITLE',
    items: [
      { title: 'MENU.ABOUT.ABOUT_PALACE_DISTRICT', slug: routesFullPaths.LANDING_THE_BUDAVARI_PALACE_DISTRICT },
      { title: 'MENU.ABOUT.NEWS', slug: routesFullPaths.NEWS },
      { title: 'MENU.ABOUT.USEFULL_INFORMATIONS', slug: routesFullPaths.WORK_IN_PROGRESS },
    ],
  },
  {
    title: 'MENU.VISITING.TITLE',
    items: [
      { title: 'MENU.VISITING.THINGS_TO_SEE', slug: routesFullPaths.SIGHTS },
      { title: 'MENU.VISITING.OUR_PROGRAMS', slug: routesFullPaths.PROGRAMS },
      { title: 'MENU.VISITING.MUSEUMPATOLOGY', slug: routesFullPaths.WORK_IN_PROGRESS },
    ],
  },
  {
    title: 'MENU.CONTACTS.TITLE',
    items: [
      { title: 'MENU.CONTACTS.ASK_US', slug: routesFullPaths.CONTACT_US },
      { title: 'MENU.CONTACTS.VENUE_RENTAL', slug: routesFullPaths.AREA_UTILIZATION },
    ],
  },
];
